import './header-menu.scss';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {useTranslation} from 'react-i18next';
import apiClient from '../../../utils/apiClient';
import {Alert, Snackbar} from '@mui/material';

function HeaderMenu() {
  const {t, i18n} = useTranslation();
  const loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));

  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [last_name, setLastName] = useState('');

  const token = sessionStorage.getItem('token') || null;
  const decodedToken = jwtDecode(token);
  const userId = decodedToken.id;
  const userRole = decodedToken.role;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const fetchData = async () => {
    try {
      if (!token) {
        console.error('No se encontró ningún token en sessionStorage.');
        return;
      }
      let url = '';
      if (userRole === 'retailer' || userRole === 'retailerGM') {
        //console.log('entra mal')
        url = `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson/${userId}`;
      }
      if (userRole === 'pma' || userRole === 'pmaGM') {
        url = `${process.env.REACT_APP_API_URL}/pma?pmaId=${userId}`;
      }
      if (
        userRole === 'admin' ||
        userRole === 'marketAdmin' ||
        userRole === 'countryAdmin' ||
        userRole === 'shippingAdmin' ||
        userRole === 'superAdmin'
      ) {
        //console.log('entra bien')
        url = `${process.env.REACT_APP_API_URL}/admin/${userId}`;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      if (userRole !== 'superAdmin') {
        const response = await apiClient.get(url, config);
        setName(response.data.name);
        setLastName(response.data.last_name);
        const userLanguage = response.data.preferred_language ;
        i18n.changeLanguage(userLanguage.toLowerCase());

        sessionStorage.setItem('loggedUser', JSON.stringify(response.data));
      }
    } catch (error) {
      if (error.response) {
        setSnackbarOpen(true);
        setSnackbarMessage(t('common-internal-server-error'), error);
      }
    }
  };

  useEffect(() => {
    if (!loggedUser) {
      fetchData();
    } else {
      setName(loggedUser.name);
      setLastName(loggedUser.last_name);
      const userLanguage = loggedUser.preferred_language ;
      i18n.changeLanguage(userLanguage.toLowerCase());
    }
  }, [i18n, token, userId, userRole]);

  const handleLogout = async () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refresh_token');
    navigate('/login/logout');
  };

  const handleHome = () => {
    if (
      userRole === 'admin' ||
      userRole === 'marketAdmin' ||
      userRole === 'countryAdmin'
    ) {
      navigate(`/admin/main/`);
    } else if (
      userRole === 'retailer' ||
      userRole === 'retailerGM'
    ) {
      navigate(`/retailer/main/`);
    } else if (userRole === 'pma') {
      navigate(`/pma/main/`);

    } else if (userRole === 'shippingAdmin') {
    navigate(`/shipping-admin/main/`);

  }
  };
  const handleSettings = () => {
    if (!token) {
      console.error('No se encontró ningún token en sessionStorage.');
      return;
    }
    try {
      switch (userRole) {
        case 'retailer':
        case 'retailerGM':
          navigate('/retailer/settings/');
          break;
        case 'pma':
        case 'pmaGM':
          navigate('/pma/settings/');
          break;
        case 'superAdmin':
          navigate('/super-admin/settings/');
          break;
        case 'shippingAdmin':
          navigate('/shipping-admin/settings/');
          break;
        case 'admin':
        case 'marketAdmin':
        case 'countryAdmin':
          navigate('/admin/settings/');
          break;
        default:
          console.error('Rol de usuario no reconocido:', userRole);
      }
    } catch (error) {
      console.error('Error al decodificar el token:', error);
    }
  };

  const DropDownMenu = () => {
    return (
      <PopupState variant="popover" popupId="demo-popup-menu" disableAutoFocus={true}>
        {(popupState) => (
          <React.Fragment>
            <Button
              variant="contained"
              {...bindTrigger(popupState)}
              className="dropdown"
            >
              <span className="data">
                {name} {last_name}
              </span>{' '}
              <span className="icon-user"></span>
            </Button>
            <Menu className="dropdown-menu submenu" {...bindMenu(popupState)}>
              <MenuItem onClick={handleHome}>{t('common-home')}</MenuItem>
              <MenuItem onClick={handleSettings}>
                {t('common-settings')}
              </MenuItem>
              <MenuItem onClick={handleLogout}>{t('common-logout')}</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    );
  };

  return (
    <div>
      <DropDownMenu/>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{width: '100%'}}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default HeaderMenu;
